/* ============================================ *
 * Search - Skip Link
 * ============================================ */

 .skip-search {
    width: 40px;
    .icon {
        &:after {
            content: "\e090";
            font-family: 'simple-line-icons';
            font-weight: 900;
        }
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 2rem;
        color: inherit;
        width: 40px;
        height: 70px;
        background: none;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

    .skip-search {
        display: none;
    }

}

/* ============================================ *
 * Search - Skip Content
 * ============================================ */

#header-search {
    padding: 20px;
    margin-top: 20px;
}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

    #header-search {
        display: block; // Force visibility
        position: static;
        top: 0;
        right: 0;
        width: 100%;
        padding: 0;
    }

}

/* ============================================ *
 * Search Input
 * ============================================ */

#search_mini_form .input-box {
    position: relative;
    padding-top: 0;
}

#search_mini_form label {
    display: none;
}

#search {
    width: 100%;
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
    @include inputTransparentBordered(1.3rem);
    @include bp(max-width, $bp-xxlarge) {
        height: 50px;
    }
    @include bp(max-width, $xs-bootstrap){
        padding-left: 40px;
        padding-right: 55px;
    }
}

#search_mini_form .search-button {
    color: white;
    position: absolute;
    top: 19%;
    right: 15px;
    width: 40px;
    height: 40px;
    border: 0;
    background: none;
}

#search_mini_form .search-button {
    span {
        display: none;
    }
    &:before {
        content: "\e090";
        font-family: 'simple-line-icons';
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: inherit;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 30px;
        background: none;
        margin-left: -15px;
        margin-top: -15px;
    }
}

#search_mini_form .search-button:hover:before {
    opacity: 0.8;
}

#search_mini_form .search-button:active:before {
    margin-top: -15px + 2px;
    margin-left: -15px + 2px;
}
