.firmaMito {
    line-height: 1.5rem;
    a{
        &:first-child{
            img{
                margin-right: 10px;
            }
        }  
    }
}

.firmaMito img {
    margin-left: 5px;      
}

.firmaMito .container {
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    @include bp(min-width, $xs-bootstrap+1){
        text-align: right;
        justify-content: flex-end;
        padding-right: 15px;
        flex-direction: row;
    }
}

.firmaMito .container,
.firmaMito .container a {
    color: $c-gray;
    font-size: 12px;
    line-height: $f-size-xxl + 5px;
    text-decoration: none;
    font-family: 'Lato', sans-serif;
    display: flex;
    align-items: center;
    @include bp(max-width, $xs-bootstrap){
        font-size: 13px;
        
    }
}

.firmaMito .container a:active,
.firmaMito .container a:focus,
.firmaMito .container a:hover {
    text-decoration: underline !important;
}

.firmaMito img {
    width: auto;
    height: 12px;
    @include bp(min-width, $xs-bootstrap+1){
        height: 15px;
    }
}
