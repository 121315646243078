/* ============================================ *
 * Review - Customer
 * ============================================ */
 .commentAll {
     cursor: pointer;
    color: $c-action;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
 }
.shadowConstant {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 200px;
    background: none;
    background: -moz-linear-gradient(top, transparent 0%, #FFF 80%);
    background: -webkit-linear-gradient(top, transparent 0%, #FFF 80%);
    background: linear-gradient(to bottom, transparent 0%, #FFF 80%);
    border-radius: 0;
    pointer-events: none;
}
.btn-escribirComentario {
    display: none;
    background: $primaryGradient;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 15px;
    border: none;
    margin: 0 auto;
    margin-bottom: 20px;
    &:hover {
        cursor: pointer;
        background: $c-red;
    }
}
.btn-verComentarios {
    display: none;
    background: $primaryGradient;
    color: white;
    font-size: 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 5;
    border: none;
    font-weight: 400;
    padding: 10px 15px;
    transform: translateX(-50%);
    transform: -webkit-translateX(-50%);
    transform: -moz-translateX(-50%);
    transform: -o-translateX(-50%);
    &:hover {
        cursor: pointer;
        background: $c-red;
    }
}
#customer-reviews {
    @include transition-property(all);
    @include transition-duration(300ms);
}
.sinActivar {
    #customer-reviews {
        max-height: 400px;
        overflow: hidden;
        @media all and (min-width: 769px) {
            padding-top: 50px;
        }
    }
    .btn-escribirComentario {
        display: none;
    }
    .btn-verComentarios {
        display: block;
    }
}
.activado {
    #customer-reviews {
        max-height: 100%;
        overflow: auto;
    }
    .btn-escribirComentario {
        display: block;
    }

    .btn-verComentarios {
        display: none;
    }
    #reviews-write {
        display: none;
    }
    #reviews-read {
        margin: 0 auto;
    }
    .shadowConstant {
        display: none;
    }
}
#customer-reviews {
    width: auto;
    float: none;

    .review-heading {
        border-top: 1px solid $c-module-border;
        border-bottom: 1px solid $c-module-border;
        padding: $gap 0 5px;

        &:after {
            @include clearfix;
        }

        h2 {
            float: left;
            display: block;
        }

        .pager {
            clear: none;
            float: right;
            width: auto;

            .count-container {
                .limiter {
                    margin-bottom: 0;

                    label {
                        font-family: $f-stack-sans;
                        font-size: 10px;
                        text-transform: uppercase;
                    }
                }
            }

            .amount {
                display: none;
            }
        }
    }

    h2 {
        color: $c-blue;
        font-size: $f-size-xs;
        text-transform: uppercase;

        span {
            color: $c-text;
        }
    }

    h3 {
        font-family: $f-stack-sans;
        font-size: $f-size-xl;
        font-weight: 600;
        text-transform: none;
        display: none;
        text-align: center;

        span {
            color: $c-blue;
        }
    }

    .fieldset {
        padding-top: 25px;
        width: 470px;

        h4 {
            border-bottom: 1px solid $c-module-border;
            font-family: $f-stack-sans;
            font-size: $f-size-xs;
            font-weight: normal;
            padding-bottom: 5px;
            text-transform: uppercase;

            em {
                display: none;
            }
        }

        .form-list {
            font-family: $f-stack-sans;
            font-size: $f-size-xs;
            font-weight: normal;
            margin: $gap 0;
            text-transform: uppercase;
            width: 470px;

            .inline-label {
                label {}

                .input-box {}

                &:after {
                    @include clearfix;
                }
            }

            label {
                font-size: $f-size-xs;
                font-weight: normal;
            }

            textarea {
                border: 1px solid $c-module-border;
                border-radius: 0;
                min-width: 100%;
                -webkit-appearance: none;
            }

            input {
                border: 1px solid $c-module-border;
                border-radius: 0;

                &[type="text"] {
                    width: 100%;
                }
            }
        }
    }

    .buttons-set {
        border: none;
        margin: 0;
        width: 470px;
    }
    .titleReview, dt {
        text-transform: none;
        font-size: $f-size-xl;
        font-weight: 600;
    }

    dl {
        font-family: $f-stack-sans;
        font-size: $f-size-xs;
        font-weight: normal;
        margin-bottom: $gap;
        color: $c-gray;

        dt {
            margin: 0;
            margin-bottom: 0;
            text-transform: none;
        }

        dd {
            font-family: $f-stack-sans;
            font-size: $f-size-l;
            line-height: 1.8;
            margin: 0;
            color: $c-gray;
            border-bottom: 1px solid #d2d2d2;
            padding-bottom: 10px;
            margin-bottom: 10px;
            &:last-child {
                border: none;
            }

            .ratings-table {
                font-family: $f-stack-sans;
                font-size: $f-size-xs;
                width: 100%;
                margin: 0;

                tr {
                    margin-right: 15px;
                }

                .review-label {
                    width: 300px;
                    max-width: 70%;
                }

            }

            table {
                margin: 15px 0;
            }

            .review-meta {
                color: $c-blue;
                font-family: $f-stack-sans;
                font-size: 10px;
                font-weight: normal;
                text-transform: uppercase;
            }
        }
    }
    .rating-box {
        top: 3px;
    }
}
.ratingNumber {
    font-size: $f-size-l;
    font-weight: 600;
    color: $c-gray;
}

.review-summary-table {
    border-bottom: 1px solid $c-module-border;
    margin: 0 0 $gap;

    thead {
        background: transparent;

        th {
            background: transparent;
            border: none;
            padding: 15px 7px 10px 8px;
        }
    }

    tbody {
        th {
            background: #FFFFFF;
            font-size: $f-size-xs;
        }

        td {
            border: none;
            text-align: center;
            padding: 0;

            label {
                width: 100%;
                display: block;
                padding: 11px 0;
            }
        }
    }

    .rating-box {
        .rating-number {
            display: none;

            &:after {
                content: "";
                display: inline-block;
                @extend .icon-sprite;
                background-position: 0px -599px;
                width: 13px;
                height: $f-size;
                margin-left: 5px;
                position: relative;
                top: 2px;
            }
        }
    }

}

/* ============================================ *
 * Ratings - Global
 * ============================================ */

.ratings-table {
    font-family: $f-stack-sans;
    font-size: $f-size-xs;
    text-transform: uppercase;
    width: 100%;
    th {
        padding-right: 0;
        text-align: center;
    }

    .rating-box {
        font-size: 0.825rem !important;
        @include bp(max-width, $xs-bootstrap){
            font-size: 10px !important;
        }
    }

    thead {
        td.value {
            text-align: center;
            padding-bottom: 5px;
            input {
                display: inline-block;
            }
        }
    }
}

#reviews-read .ratings-table tr th {
    font-weight: 400;
    display: none;

    #customer-reviews>h3 {
        display: none;
    }
}

#review-form {

    input[type=email],
    input[type=search],
    input[type=number],
    input[type=password],
    input[type=tel],
    input[type=text],
    textarea {
        width: 100%;
        max-width: 100%;
        min-height: 44px;
    }
    textarea {
        min-height: 170px;
    }

    .buttons-set {
        text-align: left;
        border: none;
        padding: 0;
        margin: 0;

        button {
            float: left;
            @include buttonXL;
            display: block;
            width: auto;
            margin: 0;
        }
    }
}

// ---------------------------------------------

@include bp(max-width, $bp-medium) {
    body.review-product-list {
        .product-view {
            .product-shop {
                .price-info {
                    padding-left: 0;
                }

                .extra-info {
                    padding: 0;
                    min-width: 84%;

                    .ratings-table {
                        margin: $gap 0;
                        width: 100%;

                        tr {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }
}

// ---------------------------------------------

@include bp(max-width, 535px) {
    body.review-product-list {
        .product-view {
            .product-shop {
                .extra-info {
                    min-width: 0;

                    .ratings-table {
                        tr {
                            float: none;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

// ---------------------------------------------

@include bp(max-width, 520px) {
    #customer-reviews {
        .fieldset {
            width: 100%;

            .form-list {
                width: 100%;

                .inline-label {

                    label,
                    .input-box {
                        float: none;
                    }
                }
            }
        }

        .buttons-set {
            width: 100%;
        }

        .review-summary-table {

            thead {
                th {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }

            tbody {
                th {
                    padding-left: 0px;
                    padding-right: 0px;
                }

                td {
                    width: 20%;
                    padding: 0px;
                }
            }

            .rating-box {
                text-indent: 0px;
                text-align: center;
                background: none;
                height: $f-size * 1.4;
                width: 100%;

                .rating {
                    display: none;
                }

                .rating-number {
                    display: inline-block;
                }
            }
        }

    }
}

// ---------------------------------------------

@include bp(max-width, 450px) {
    #customer-reviews dl dd .ratings-table tr {
        float: none;
    }
}

/* ============================================ *
 * Review View Page
 * ============================================ */
.review-product-view {
    .product-review {
        .product-details {
            h2 {
                border-bottom: none;
            }
        }

        .product-img-box {
            width: auto;
            max-width: 50%;

            >a {
                width: 100%;
            }

            .product-image {
                width: 100%;
            }
        }
    }
}

@include bp('max-width', $bp-medium) {
    .review-product-view {
        .product-review {
            .product-img-box {
                max-width: 100%;
            }
        }
    }
}
