/* ============================================ *
 * Wishlist
 * ============================================ */

 #wishlist-table {
    th {
        text-align: center;
    }

    &.clean-table {
        font-size: 13px;
        text-transform: capitalize;
        color: gray;
        width: 100%;

        th {
            border-bottom: 1px solid $c-table-border;
        }

        td {
            padding: 15px;
            vertical-align: top;
        }

        thead th {
            font-size: 13px;
            text-transform: capitalize;
            color: gray;
        }
    }

    .product-name {
        font-size: $f-size-xl;
        margin-bottom: 0;
        text-transform: uppercase;

        a {
            color: $primary-color;
            font-weight: 400;
            @include bp(max-width, $bp-xsmall){
                font-size: 16px;
            }
        }
    }

    .wishlist-sku {
        font-size: $f-size-xxs;
        margin: 5px 0;
    }

    textarea {
        border: 1px solid lightgray;
        width: 100%;
        height: 55px;
        font-size: $f-size-xxs;
        transition: all .25s;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &::-webkit-input-placeholder {
            font-size: 12px;
        }
        &:-moz-placeholder {
            font-size: 12px;
        }
        &::-moz-placeholder {
            font-size: 12px;
        }
        &:-ms-input-placeholder {
            font-size: 12px;
        }

        &:focus {
            border: 1px solid $primary-color;
            outline: none;
        }
    }

    .item-manage {
        text-align: right;
        max-width: 450px;
        padding-top: 5px;
        .button {
            font-size: 12px;
            padding: 3px 20px;
            height: 40px;
            background: #F3F3F3;
            color: gray;
            border-radius: $default-border-radius;
            text-transform: lowercase;
            transition: all .25s;
                &:first-letter{
                    text-transform: uppercase;
                }
            &:hover{
                background: lightgray;
                color: $c-gray;
            }
            @include bp(max-width, $bp-xsmall){
                width: 100%;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }
    }

    .cart-cell {
        text-align: center;
    }

    td {
        &.customer-wishlist-item-image {
            width: 113px;
        }

        &.customer-wishlist-item-quantity {
            width: 3em;
        }

        &.customer-wishlist-item-price {
            width: 120px;
            text-align: center;
        }

        &.customer-wishlist-item-cart {
            width: 150px;

            .button {
                font-size: 12px;
                margin-bottom: $gap;
                padding: 3px 5px;
                width: 100%;
                background: $primary-color;
                height: 40px;
                border-radius: $default-border-radius;
                text-transform: lowercase;
                transition: all .25s;
                &:first-letter{
                    text-transform: uppercase;
                }
            }

            .truncated {
                margin-bottom: $gap;
            }

            > p {
                margin-bottom: 0;
            }

            .remove-whishlist-item {
                text-align: center;
            }

            .btn-remove {
                vertical-align: top;
            }
            .link-edit{
                background: #F3F3F3;
                padding: 10px 5px;
                color: gray;
                &:hover{
                    color:$c-gray;
                    background: lightgray;
                }
            }
        }

        &.customer-wishlist-item-remove {
            width: 20px;
        }

        .button,
        button {
            white-space: normal;
        }

    }

    .price-box {
        font-size: $f-size-xs;

        .price {
            color: $primary-color;
        }
    }

    .giftregisty-add {
        margin-top: 5px;

        .change {
            display: none;
        }

        li {
            cursor: pointer;
            color: $c-action;
            margin-bottom: 3px;
        }
    }

    .truncated {
        .details {
            background: none;
            color: $c-action;
        }
    }

    td[data-rwd-label]:before {
        font-weight: 600;
    }
}

.my-wishlist{
    margin-top: 25px;
    @include bp(max-width, $xs-bootstrap){
        thead{
            display: none;
        }
    }
    .page-title{
        border-bottom: 1px solid lightgray;
        h1{
            font-size: 20px;
            position: relative;
            color: #454545;
            font-weight: bold;
            text-transform: uppercase;
            display: table;
            border-bottom: 4px solid $primary-color !important;
            padding-bottom: 8px;
            margin: 0;
        }
    }
    .messages{
        .success-msg{
            li {
                color: $c-green;
                background-color: white;
                border-radius: $default-border-radius;
                border: 1px solid $c-green;
                margin-top: 15px;
                &:before {
                    border-left: 6px solid $c-green;
                }
                span{
                    a{
                        font-weight: bolder;
                        color: $c-green;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .buttons-set{
        padding-top: 15px;
        .button.button{
            min-width: 140px;
            height: 40px;
            border-radius: $default-border-radius;
            font-size: 12px;
            transition: all .25s;
            &.btn-update{
                background:#F3F3F3;
                color: gray;
                &:hover{
                    background: lightgray;
                    color: $c-gray;
                }
            }
        }
        .back-link{
            a{
                color: gray;
            }
        }
    }
    .input-text.qty{
        border-radius: $default-border-radius;
        border-color: lightgray;
    }
    .customer-wishlist-item-image{
        padding-left: 0 !important;
        padding-top: 20px !important;
        .product-image{
            border: 1px solid $primary-color;
            border-top-left-radius: 10px;
            overflow-y: hidden;
            border-bottom-right-radius: 10px;
        }
    }
    .price-box{
        margin: 0;
        display: flex;
        flex-direction: column-reverse;
        .discount-label{
            display: none;
        }
        .special-price{
            .price-label{
                display: none;
            }
            .price{
                font-family: inherit;
                color: $primary-color !important;
            }
        }
        .old-price{
            margin-bottom: 0;
            .price-label{
                display: none;
            }
            .price{
                font-size: 12px;
                color: gray !important;
                font-weight: 300;
                @include bp(max-width, $bp-xsmall){
                    display: none;
                }
            }
        }
        .special-price{
            @include bp(max-width, $bp-xsmall){
                margin-bottom: 0;
            }
            .price{
                @include bp(max-width, $bp-xsmall){
                    margin-top: 2px;
                }
            }
        }
    }
    .btn-remove2{
        background-image: none;
        text-indent: 0;
        margin-top: 20px;
        transition: all .25s;
        &:before{
            content: "\f1f8";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 1.2rem;
            color: $third-color;
        }
    }
    .availability.out-of-stock{
        color: gray;
        padding: 9px;
        border: 1px solid lightgray;
        font-size: 12px;
        &:before{
            display: none;
        }
    }
}

.wishlist-index-index{
    strong{
        padding: 12px 0 12px 0;
    }
    .sidebar{
        .block:not(.block-layered-nav){
            .block-content{
                @include bp(max-width, $bp-medium){
                    border: 1px solid lightgray;
                    padding: 2px 15px;
                    transition: all .5s ease;
                    &.no-display{
                        border-top-right-radius: 0px;
                    }
                }
            }
        }
        .block-account{
            .block-content {
                padding: 6px 15px 6px;
                transition: all .5s ease;
                border-top-right-radius: 0px;
                li{
                    font-family: inherit;
                    a{
                        color: $c-gray;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                    strong{
                        font-weight: 600;
                        color: $primary-color;
                    }
                }
            }
        }
    }
    .footer{
        .block-title{
            @media only screen and (max-width: 479px){
                padding-top: inherit !important;
            }
            span{
                font-size: inherit;
                border-bottom: none;
                display: inherit;
                padding-bottom: inherit;
                margin-bottom: inherit;
                padding-top: inherit !important;
                &:after{
                    content: none !important;
                }
            }
        }
    }
}
// ---------------------------------------------

@include bp(max-width, $bp-xlarge) {
    #wishlist-table {
        &.clean-table {
            td {
                padding-left: $box-spacing;
                padding-right: $box-spacing;
            }
        }
    }
}

// ---------------------------------------------

@include bp(max-width, $bp-large) {
    #wishlist-table {
        &.clean-table td {
            padding-left: 5px;
            padding-right: 5px;

            textarea {
                height: 100px;
            }

            &.customer-wishlist-item-cart {
                width: 110px;
            }
        }

        .product-name {
            font-size: $f-size-s;
        }
    }
}

// ---------------------------------------------

@include bp(max-width, $bp-medium) {
    #wishlist-table {
        &:after {
            @include clearfix;
        }

        .product-name {
            font-size: $f-size-xl;
        }

        textarea {
            height: 68px;
            max-width: 100%;
            margin-top: 10px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 10px;
        }

        .item-manage {
            max-width: 100%;
            padding-top: 0;
        }

        tr {
            position: relative;

            &:after {
                @include clearfix;
            }
        }

        td.customer-wishlist-item-image {
            width: 70px;

            img {
                width: 70px;
                height: 70px;
            }
        }

        td.customer-wishlist-item-cart {
            p {
                margin-bottom: 0;
            }

            .remove-whishlist-item {
                margin-right: 0;
                padding-top: 4px;
            }
        }

        td.customer-wishlist-item-cart {
            padding-right: 15px;
        }
    }
}

// ---------------------------------------------

@include bp(max-width, $bp-small) {
    #wishlist-table {
        td.customer-wishlist-item-quantity {
            padding-left: 0;
        }

        td.customer-wishlist-item-image,
        th.customer-wishlist-item-image {
            display: none;
        }
    }
}

// ---------------------------------------------

@include bp(max-width, $bp-xsmall) {
    #wishlist-table {
        tr {
            position: relative;
        }

        th.customer-wishlist-item-image {
            display: block;
        }
        .customer-wishlist-item-quantity{
            width: 40% !important;
            display: inline-block;
            padding: 5px 0 !important;
        }
        .customer-wishlist-item-price{
            width: 40% !important;
            display: inline-block;
            padding: 5px 0 !important;
        }
        td {
            &.customer-wishlist-item-image,
            &.customer-wishlist-item-info,
            &.customer-wishlist-item-quantity,
            &.customer-wishlist-item-price,
            &.customer-wishlist-item-cart {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                empty-cells: hide;
            }

            &.customer-wishlist-item-image {
                display: unset;
                overflow: hidden;

                a {
                    float: left;
                    margin: 20px 10px 10px 0;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
            }

            &.customer-wishlist-item-quantity,
            &.customer-wishlist-item-price {
                text-align: left;

                &:before {
                    float: left;
                    margin-right: 5px;
                    padding-top: 7px;
                    font-size: 10px;
                }

                .cart-cell {
                    text-align: left;
                }

                .price-box {
                    margin-top: 0;
                }
            }

            &.customer-wishlist-item-cart {
                .cart-cell {
                    float: left;
                    width: 48%;
                }

                > p {
                    float: right;
                    width: 48%;
                }
            }

            &.customer-wishlist-item-remove {
                position: absolute;
                right: 0;
                bottom: 65px;
                width: 15%;
                border-left: 1px solid lightgray;
                height: 50px;
                .btn-remove2{
                    height: 22px;
                    width: 22px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: 0;
                }
            }
        }

        &.clean-table {
            td.customer-wishlist-item-cart {
                width: 100%;
            }
        }
    }
}

/* ============================================ *
 * Wishlist Sidebar
 * ============================================ */

.block-wishlist {

    .mini-products-list > li:not(:last-child) {
        padding-bottom: 5px;
    }

    .product-details .product-name {
        padding-top: 0;
        margin-bottom: 5px;
    }

    .price-box {
        float: left;
        margin: 0;
    }

    .price-box,
    .price-box .price,
    .link-cart {
        font-size: $f-size-xs;
    }

    .link-cart {
        float: left;
        text-transform: uppercase;
        margin-right: 7px;
        padding-right: 7px;
        border-right: 1px solid $c-module-border-light;
    }

}

.wishlist-index-share{
    .fieldset{
        box-shadow: 0px 0px 25px 0px rgba(80, 80, 80, 0.1);
        border-radius: 20px 0px;
        padding: 25px 20px;
        .form-list {
            display: flex;
            flex-wrap: wrap;
            .wide{
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 5px;
                }
                @include bp(min-width, $bp-small+1){
                    margin-bottom: 10px;
                }
                @include bp(min-width, $bp-large+1){
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding-right: 15px;
                    &:last-child{
                        padding-left: 15px;
                        padding-right: 0;
                    }
                }
                label{
                    font-family: inherit;
                }
                textarea{
                    width: 100%;
                    max-width: 100%;
                    height: 100% !important;
                    min-height: 170px !important;
                    border: 1px solid #C0C0C0;
                    border-radius: 5px;
                    transition: all .25s;
                    &:focus{
                        border: 1px solid $primary-color;
                        outline: none;
                    }
                }
            }
        }
    }
}

