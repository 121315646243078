.cms-terminos-y-condiciones{
    .col-auto{
        width: 100%;
        padding: 0;
    }
    h2{
        line-height: 40px;
        font-size: 22px;
        color: $c-gray !important;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 1rem;
        text-align: center;
        letter-spacing: 0.5px;
        padding: 0;
        margin-top: 3rem;
    }
    .text-header{
        margin-top: 20px;
    }
    .accordion{
        .icon-arrow-down{
            &:before{
                position: absolute;
                right: 15px;
                top: 18px;
            }
        }
        .drop.collapsed {
            border-bottom: 1px solid white;
            background: white;
            h5{
                color: $c-gray;
                transition: all .25s;
                margin-left: 0px;
            }
            .simple-icon{
                transition: all .2s ease;
                &:before{
                    transform: rotate(0deg);
                    transition: all .2s ease;
                }
            }
        }
        .drop {
            border-bottom: 1px solid lightgray;
            transition: all .25s;
            background: rgba(243, 243, 243, .5);
            h5 {
                color: $primary-color;
                transition: all .25s;
                margin-left: 15px;
            }
            .simple-icon{
                &:before{
                    transform: rotate(180deg);
                    transition: all .2s ease;
                }
            } 
        }
        .card{
            margin: 10px 0;
            border-radius: 5px !important;
            border: 1px solid lightgray !important;
            cursor: pointer;
            .card-body{
                p{
                    font-weight: 300;
                }
                a{
                    color: $primary-color;
                    font-weight: bold;
                }
                ul{
                    li{
                        font-weight: 300;
                    }
                }
            }
        }
    }
    .faq-banner{
        margin-top: 20px;
        border-radius: 10px;
    }
}

.faq-content{
    li{
        list-style: none;
        overflow: unset !important;
        margin-left: 15px !important;
        &:before{
            content: "";
            position: absolute;
            margin-top: 10px;
            margin-left: -13px;
            width: 5px;
            height: 5px;
            background-color: $primary-color;
            border-radius: 50%;
        }
        li{
            &:before{
                background-color: #ccc;
                width: 3px;
                height: 3px;
            }
        }
    }
}

