/* ============================================ *
 * Nav - Skip Link
 * ============================================ */

.skip-nav {
	width: 40px;

	&:not(.skip-active):hover {
		text-decoration: none;

		.icon {
			background-position: -50px+4px ($toolbar-icon-padding-offset/2);
		}
	}
}

.skip-nav {
	position: relative;
}

.skip-nav .label {
	display: block;
	position: relative;
	top: -25px;
	left: 0;
	font-size: 0.825rem;
	line-height: 1rem;
	color: white;
	font-weight: 100;
	font-family: $f-stack-sans;
}

.menu-link-desplegable .icon,
.skip-nav .icon {
	&:after {
		content: "\f0c9";
		font-family: "Font Awesome 5 Free";
	}

	font-weight: 900;
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: inherit;
	width: 40px;
	height: 70px;
	background: none;
	padding-top: 20px;
	padding-bottom: 20px;
}
li.levelBackground {
	display: none !important;
}
.header-desplegable {
	#header-nav {
		display: none;
	}

	.nav-primary {
		width: 100%;
		@media all and (min-width: 768px) {
			background: $primaryHeader;
		}

		li {
			position: static;
			@media all and (min-width: 768px) {
				border-right: 1px solid $activeHeader;
			}

			&.first.level0>a {
				padding-left: 25px !important;
			}
			@media all and (min-width: 768px) {
				&.menu-active {
					>a {
						color: white;
						background: $activeHeader;
					}
				}

				>a {
					color: white;
					@include transition-property(background);
					@include transition-duration(300ms);
					border: none;

					&:hover,
					&:active,
					&:focus {
						color: white;
						background: $activeHeader;
					}
				}
			}
		}
		@media all and (min-width: 768px) {
			ul.level0 {
				width: 100%;
				border: none;
				padding: 15px;
				min-height: 140px;
				background: $activeHeader;
				max-width: 65%;
				top: 60px;

				li {
					float: left;
					border: none;
					width: 25%;

					a {
						border: none;
						padding: 5px;

						&:hover {
							font-weight: 700;
						}
					}
					&.levelBackground {
						float: none;
						display: none;
						width: 100%;
						max-width: 35%;
						height: 100%;
						background: $activeHeader;
						border: none;
						padding: 15px;
						position: absolute;
						top: 0;
						left: 830px;
						max-width: calc(1282px - 100%);
						box-sizing: border-box;
						img {
							max-width: 100%;
							height: auto;
						}
					}
				}
			}
		}
		.menu-active ul.level0 li.levelBackground {
			display: none !important;
		}
		@media all and (min-width: 768px) {
			.menu-active ul.level0 li.levelBackground {
				display: block !important;
			}
		}
	}
}
.menuActive {
	.menu-col-desplegable {
		background: $activeHeader;
	}

	#header-nav {
		display: block;
		width: 100%;
		position: absolute;
		z-index: 100;
		top: 92px;
		left: 0;
		max-width: 100%;
		margin: 0 auto;
		background: $activeHeader;

		#nav .nav-secondary {
			display: none !important;
		}
	}
}
.menu-col-desplegable {
	@media all and (max-width: 768px) {
		display: none !important;
	}
}
.menu-link-desplegable {
	color: white;
	text-align: center;
	cursor: pointer;

	.icon {
		display: block;
		width: 100%;
		height: 50px;
		font-size: 2.8rem;
		line-height: 1.2rem;
	}

	.label {
		font-size: 1rem;
	}
}

// ---------------------------------------------

@include bp(min-width, 500px) {

	.skip-nav {
		width: 25%;
	}

}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

	.skip-nav {
		display: none;
	}

}

/* ============================================ *
 * Nav - Skip Content
 * ============================================ */

 @include bp(max-width, $bp-medium) {

	#header-nav {
		padding: 5px 0;
	}

}

@-webkit-keyframes slide-in-left {
	0% {
	  -webkit-transform: translateX(-1000px);
			  transform: translateX(-1000px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	  opacity: 1;
	}
  }
  @keyframes slide-in-left {
	0% {
	  -webkit-transform: translateX(-1000px);
			  transform: translateX(-1000px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	  opacity: 1;
	}
  }


@include bp(max-width, $xs-bootstrap) {
	.skip-content.skip-active#header-nav {
		position: fixed;
		z-index: 100;
		padding: 0;
		height: 100%;
		width: 95vw;
		background: $primary-color;
		-webkit-box-shadow: -8px 0px 21px 5px rgba(0,0,0,0.53);
		-moz-box-shadow: -8px 0px 21px 5px rgba(0,0,0,0.53);
		box-shadow: -8px 0px 21px 5px rgba(0,0,0,0.53);

		-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

	}
}

.top-menu-left{
	background: #303030;
	font-weight: bold;
	text-transform: uppercase;
	padding: 10px 0 10px 10px;
	color: white;
	height: 41px;
	a{
		padding: 10px;
		margin-top: -10px;
		margin-right: 6px;
	}
	p{
		margin: 0;
	}
	i{
		color: white;
		font-size: 17px;
	}
}

// ---------------------------------------------

@include bp(min-width, $bp-medium + 1) {

	#header-nav {
		display: block;
		/* Force visibility */
	}

}

/* ============================================ *
 * Nav
 * ============================================ */
 nav#nav {
	position: relative;

	@include bp(max-width, $xs-bootstrap) {
		position: static;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
	}
}

@include bp(max-width, $xs-bootstrap){
	#header-account{
		position: fixed;
		z-index: 100;
		padding: 0 !important;
		height: 100%;
		width: 60vw;
		left: unset;
		right: 0;
		background: #303030;
		-webkit-box-shadow: 7px 0px 29px 0px rgba(0,0,0,0.53);
		-moz-box-shadow: 7px 0px 29px 0px rgba(0,0,0,0.53);
		box-shadow: 7px 0px 29px 0px rgba(0,0,0,0.53);

		-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		a{
			&:hover{
				color: white !important;
				text-decoration: none;
			}
		}
	}
	@-webkit-keyframes slide-in-right {
		0% {
			-webkit-transform: translateX(1000px);
					transform: translateX(1000px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateX(0);
					transform: translateX(0);
			opacity: 1;
		}
	}
	@keyframes slide-in-right {
		0% {
			-webkit-transform: translateX(1000px);
					transform: translateX(1000px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateX(0);
					transform: translateX(0);
			opacity: 1;
		}
	}
	#header-account{
		li{
			&:nth-child(5){
				a{
					background: $gradient-bg;
					color: $primary-color;
					border-radius: 50px;
					margin: 0px 10px 10px 10px;
					font-weight: bold;
					line-height: 35px;
					display: table;
    				float: right;
				}
			}
			&:nth-child(6){
				a{
					background: $gradient-bg;
					color: $primary-color;
					border-radius: 50px;
					margin: 0 10px;
					font-weight: bold;
					line-height: 35px;
					display: table;
    				float: right;
				}
			}
			a{
				text-align: right;
				padding: 0 25px 0 15px;
				line-height: 45px;
			}
		} 
		.top-menu-left{
			background: $primary-color;
			padding: 10px 10px 10px 0;
			a{
				height: 41px;
				i{
					padding-top: 7px;
				}
			}
		}
	}
}

@include bp(min-width, $bp-medium + 1) {
	.nav-primary {
		display: inline-block;
		margin-top: 0;
	}
}

/* ============================================ *
 * General Navigation Styles
 * ============================================ */

$nav-primary-height: 80px;

.nav-primary {
	position: relative;
	z-index: 1;

	a {
		text-decoration: none;
		position: relative;
		display: block;
		color: $c-text;
		font-size: 1.2rem;
		line-height: $nav-primary-height;
		font-family: $f-stack-sans;
		@include transition-property(color);
		@include transition-duration(300ms);

		@include bp(max-width, $bp-xxlarge) {
			line-height: $nav-primary-height - 15px;
			font-size: 1.2rem;
		}
	}

	li {
		position: relative;
	}

	li.level1 a {
		border-bottom: none;
		font-size: 13px;
		font-weight: 300;
		line-height: 1;
		padding: 10px 15px 10px 25px;
		@media all and (max-width: 767px) {
			font-size: 1.2rem;
			color: white;
			background: rgba(0, 0, 0, .1);
		}
	}
}

.header-desplegable .nav-primary {
	a {
		line-height: 60px;
	}
}

.nav-secondary {
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;

	@include bp(max-width, $xs-bootstrap) {
		display: none !important;
	}

	li {
		max-width: 140px;
		position: relative;
		padding-left: 45px;

		a {
			color: $c-gray;
			font-size: 1.2rem;
			line-height: 1.2rem;
			font-weight: 400;
			text-decoration: none;
			@include transition-property(color);
			@include transition-duration(300ms);

			&:hover {
				color: $c-action;
			}

			i {
				font-size: 1.875rem;
				position: absolute;
				left: 5px;
				top: 10px;
			}
		}
	}
}

.header-desplegable .nav-secondary {
	position: static;
	width: 50%;
}

// Show sub-menus when JS adds the appropriate classes
.nav-primary .menu-active>ul.level0,
.nav-primary li.level0 li.sub-menu-active>ul {
	display: block;
}

/* ============================================ *
 * Small Viewports - Accordion Style Menu
 * ============================================ */

@include bp(max-width, $bp-medium) {

	.nav-primary {

		// Increase clickable area of anchors for small viewports
		a.level0,
		a {
			line-height: $nav-primary-height + 5px;
		}

		// Hide all sub-menus
		li.level0 ul {
			display: none;
		}

		// Indent sub-menus
		li{
			&.level0{
				li{
					padding: 0 0 0 0;
				}
			}
			&.level1{
				a{
					padding: 0 15px 0 25px;
					line-height: 35px;
				}
				li{
					background: rgba(0, 0, 0, 0.1);
				}
			}
		}
		
		li.parent>a:after {
			@include triangle(right, 5px, white !important);
			top: 50%;
			left: 10px;
			right: auto;
			margin-top: -5px;
		}

		// Menu active states
		li.parent.sub-menu-active>a:after,
		li.parent.menu-active>a:after {
			@include triangle(down, 5px, white);
			border-left: 5px solid transparent !important;
			top: 50%;
			left: 10px;
			right: auto;
			margin-top: -3px;
		}

		li.menu-active>a,
		li.sub-menu-active>a {
			color: white;
		}
	}
}

/* ============================================ *
 * Large Viewports - Dropdown Menu
 * ============================================ */
.nav-primary{
	@include bp(min-width, $bp-medium + 1){
		ul.level0,
		ul.level1,
		ul.level2{
			padding: 5px 0;
		}
		.level1.view-all,
		.level2.view-all,
		.level3.view-all{
			display: none;
		}
	} 
}


@include bp(min-width, $bp-medium + 1) {

	.nav-primary {

		a.level0 {
			padding: 0 25px;
		}

		li,
		li.level0,
		a.level0 {
			width: auto;
			display: inline-block;
			line-height: 40px;
			font-size: 14px;
			font-weight: 400;
		}

		.level1 {
			display: block;
		}

		li:last-child>a {
			border-bottom: none;
		}

		a:hover,
		li:hover>a {
			color: $c-action;
		}

		.menu-active {
			z-index: 200;
		}

		/* Default styles for 1+ drop-down menus */
		li.level0 ul {
			@include menu;
			position: absolute;
			left: 0;
			top: 40px;
			z-index: 10;
			width: 250px;
			display: none;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -4px;
			border:none;
			@include bp(max-width, $bp-xxlarge) {
				top: 40px;
			}
		}

		/* Level 2+ */
		li.level1 ul {
			left: 250px;
			top: -5px;
			li{
				display: block;
			}
		}

		/* Class for js to add briefly to evaluate element positioning. */
		li.level0 ul.level0.position-test {
			display: block;
		}

		/* Correct menus that will hang off the page (class added by js) */
		li.level0 ul.level0.spill {
			right: 0;
			left: auto;

			// Sub-menu drop down to the left
			li.level1 ul {
				left: auto;
				right: 50px;
				top: 20px;
			}
		}

		/* Level 1+ */
		li.level0 li.parent>a {
			position: relative;

			&:after {
				@include triangle(right, 4px, $gradient-first-color);
				right: 17px;
				top: 50%;
				margin-top: -3px;
			}
		}

		li.level0.first>a {
			padding-left: 0;
		}
	}
}

.nav-primary li.menu-active {
	>ul {
		display: block;
	}
}

#header-account{
	.links{
		li{
			a:before{
				font-family: "Font Awesome 5 Free";
				font-size: 13px;
				font-weight: 900;
				float: right;
				@include bp(min-width, $xs-bootstrap+1){
					color: lightgray;
					float: left;
					margin-right: 6px;
				}
			}
			&:first-child{
				a:before{
					content: "\f406";
					margin-left: 7px;
				}
			}
			&:nth-child(2){
				a:before{
					content: "\f004";
					margin-left: 7px;
				}
			}
			&:nth-child(3){
				a:before{
					content: "\f290";
					margin-left: 9px;
				}
			}
			&:nth-child(4){
				a:before{
					content: "\f09d";
					margin-left: 6px;
				}
			}	
		}
		@include bp(min-width, $xs-bootstrap+1){
			[title~=Registrarse],
			[title~=sesión]{
				&::after{
					font-family: "Font Awesome 5 Free";
					font-size: 13px;
					font-weight: 900;
					float: left;
					color: $primary-color;
					
				}
				font-weight: 600;
			}
			[title~=Registrarse]{
				&::after{
					content: "\f234";
					margin-left: 7px;
					margin-right: 3px;
				}
			}
			[title~=sesión]{
				&::after{
					content: "\f2f6";
					margin-left: 5px;
					margin-right: 7px;
					font-size: 15px;
				}
			}
		}
		
		a{
			text-align: right;
			@include bp(min-width, $xs-bootstrap+1){
				text-align: left;
			}
		}
	}
}
