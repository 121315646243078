.cms-sucursales {
    .breadcrumbs{
        padding: 15px 15px;
        margin: 0;
        @include bp(min-width, $xs-bootstrap+1){
            margin: 0 auto;
            max-width: 1280px;
        }
    }
    .page{
        background: #F3F3F3;
    }
    .main-container {
        margin-bottom: 20px;
    }
    .map-container{
        .title-container{
            .title{
                font-weight: 900;
                letter-spacing: 0.5px;
                font-size: 22px;
                margin-bottom: 5px;
                color: $c-gray;
            }
            hr{
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                width: 30px;
            }
        }
    }
}

.office-section{
    .container{
        padding-left: 5px;
        padding-right: 5px;
        @include bp(min-width, $xs-bootstrap+1){
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .title-office-container{
        margin-bottom: 30px;
        .container-fluid{
            padding: 0;
        }
        h2{
            font-weight: 900;
            letter-spacing: 0.5px;
            font-size: 22px;
            margin-bottom: 5px;
            color: $c-gray;
        }
    }
    .header {
        h1 {
            color: rgba(68, 68, 68, 1.0);
            font-family: 'Lato', Helvetica, Arial, serif;
            font-weight: 900;
            font-style: normal;
            font-size: 36px;
            color: rgba(68, 68, 68, 1.0);
            text-align: center;
            line-height: 45.0px;

            &::before {
                display: inline-block;
                content: "";
                border-top: .1rem solid rgba(68, 68, 68, 1.0);
                width: 26rem;
                margin-right: 2rem;
                transform: translateY(-1rem);
            }

            &::after {
                display: inline-block;
                content: "";
                border-top: .1rem solid rgba(68, 68, 68, 1.0);
                width: 26rem;
                margin-left: 2rem;
                transform: translateY(-1rem);
            }
        }
    }

    .direcciones {
        margin-bottom: 2.5rem;
        .dir-container{
            background: white;
            padding: 20px;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 15px -4px;
        }
        .no-image-shop{
            border-radius: 5px;
            border: 1px solid lightgray;
            margin-bottom: 10px;
            width: 170px;
            height: 102px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: gray;
            text-transform: uppercase;
            font-size: 10px;
        }
        img{
            border-radius: 5px;
            border: 1px solid lightgray;
            margin-bottom: 10px;
            width: 170px;
            height: 102px;
        }

        .m-left {
            margin-left: -15px;
        }

        .m-right {
            margin-right: -15px;
        }

        .titulo {
            border-bottom: 1px solid lightgray;
            transition: all .25s;
            &.active {
                border-bottom: 3px solid $third-color;
            }
            h2 {
                font-weight: bold;
                font-style: normal;
                font-size: 22px;
                text-transform: none;
                letter-spacing: 0.5px;
                margin-bottom: 10px;
                color: $c-gray;
                i{
                    font-size: 17px;
                    margin-right: 5px;
                }
                span{
                    font-size: 10px;
                    border: 1px solid red;
                    border-radius: 4px;
                    padding: 2px 10px;
                }
            }
        }
        .contenido {
            @include bp(min-width, $bp-small){
                padding-right: 20px;
            }
            p {
                color: $c-gray;
                font-weight: 300;
                line-height: 25px;
                font-size: 14px;

                span {
                    color: #000000;
                    font-weight: 700;
                }
            }
            a{
                color: $primary-color;
                font-weight: bold;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .localizacion {
            background: $primary-color;
            color: #ffffff;
            text-transform: uppercase;
            font-weight: 400;
            padding: 5px 25px;
            border-radius: 5px;
            font-size: 14px;
            margin: 0px auto;
            transition: all .5s;
            @media only screen and (max-width: 1270px) {
                font-size: 15px;
            }
            &.active{
                background: $primary-color;
            }
            &:focus {
               outline: none;
               box-shadow: none;
            }
            &:hover {
                background: $secondary-color;
                box-shadow: none;

            }
            .fa-map-marker-alt:before {
                font-size: 12px;
                vertical-align: middle;
            }

        }
    }

    #mapSucursales {
        height: 350px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #F3F3F3;
        .p-cartel-sucursal {
            margin: 0;
            text-align: left;
            line-height: normal;
            font-family: 'Lato', Helvetica, Arial, serif;

            &.p-cartel-sucursal-direccion {
                font-weight: 600;
                font-size: 17px;
                line-height: 25px;
                color: #333;
            }
        }

        .p-cartel-comoLllegar {
            line-height: 20px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.4px;
            color: #71b723;
            padding: 0;
            margin: 0;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    @media only screen and (min-width: 1400px) {
        .header {
            h1 {
                color: rgba(68, 68, 68, 1.0);
                font-family: 'Lato', Helvetica, Arial, serif;
                font-weight: 900;
                font-style: normal;
                font-size: 36px;
                color: rgba(68, 68, 68, 1.0);
                text-align: center;
                line-height: 45.0px;

                &::before {
                    display: inline-block;
                    content: "";
                    border-top: .1rem solid rgba(68, 68, 68, 1.0);
                    width: 29rem;
                    margin-right: 2rem;
                    transform: translateY(-1rem);
                }

                &::after {
                    display: inline-block;
                    content: "";
                    border-top: .1rem solid rgba(68, 68, 68, 1.0);
                    width: 29rem;
                    margin-left: 2rem;
                    transform: translateY(-1rem);
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .header {
            h1 {
                color: rgba(68, 68, 68, 1.0);
                font-family: 'Lato', Helvetica, Arial, serif;
                font-weight: 900;
                font-style: normal;
                font-size: 37px;
                text-align: center;
                line-height: 45.0px;

                &::before {
                    display: inline-block;
                    content: "";
                    border-top: .1rem solid rgba(68, 68, 68, 1.0);
                    width: 23rem;
                    margin-right: 2rem;
                    transform: translateY(-1rem);
                }

                &::after {
                    display: inline-block;
                    content: "";
                    border-top: .1rem solid rgba(68, 68, 68, 1.0);
                    width: 23rem;
                    margin-left: 2rem;
                    transform: translateY(-1rem);
                }
            }
        }
    }

    @media only screen and (max-width: 804px) {
        .header {
            h1 {
                color: rgba(68, 68, 68, 1.0);
                font-family: 'Lato', Helvetica, Arial, serif;
                font-weight: 900;
                font-style: normal;
                font-size: 22px;
                text-align: center;
                line-height: 45.0px;

                &::before {
                    display: inline-block;
                    content: "";
                    border-top: .1rem solid rgba(68, 68, 68, 1.0);
                    width: 3rem;
                    margin-right: 2rem;
                    transform: translateY(-1rem);
                }

                &::after {
                    display: inline-block;
                    content: "";
                    border-top: .1rem solid rgba(68, 68, 68, 1.0);
                    width: 3rem;
                    margin-left: 2rem;
                    transform: translateY(-1rem);
                }
            }
        }
    }
}

.cms-tiendas, .cms-sucursales{
    .std{
        width: 100%;
    }
}

