.customer-account-create{
    background: #f9f9f9;
    .account-create{
        margin-bottom: 30px;
        margin-top: 20px;
        .page-title{
            border-bottom: 4px solid $primary-color;
            h1{
                color: $c-gray;
                margin-bottom: 5px;
                font-size: 22px;
                font-weight: bold;
                text-transform: uppercase !important;
                padding-bottom: 0;
            }
        }
        .form-instructions{
            margin-top: 10px;
            margin-bottom: 25px;
        }
        .control{
            margin-top: 20px;
            .checkbox{
                margin-top: 1px;
                width: 16px;
            }
        }
        .left-col{
            @include bp(min-width, $xs-bootstrap+1){
                padding-right: 0px;
            }
            .register-bg{
                background-image: url(../images/bg-login.jpg);
                background-size: cover;
                background-position: 40%;
                width: 100%;
                height: 100%;
                border-radius: 0px 0 5px 5px;
                box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
                background-color: $primary-color;
                background-repeat: no-repeat;
                @include bp(min-width, $xs-bootstrap+1){
                    border-radius: 5px 0 0 5px;  
                }
                .register-terms{
                    padding: 25px 20px; 
                    h2{
                        color: white;
                        font-size: 24px;
                        font-weight: 400;
                        text-transform: none;
                        hr{
                            border: 2px solid white;
                            width: 70px;
                        }
                    }
                    p{
                        font-size: 15px;
                        color: white;
                        font-weight: 300;
                    }
                    button{
                        border-radius: 5px;
                        background: white;
                        color: $primary-color;
                        text-align: center;
                        text-transform: uppercase;
                        border: 1px solid white;
                        padding: 7px 20px;
                        height: 33px;
                        font-size: 12px;
                        transition: all .3s;
                        &:focus{
                            outline: none;
                        }
                        &:hover{
                            background: transparent;
                            color: white;
                        }
                    }
                    .buttons-set{
                        width: 100%;
                        a{
                            padding: 0;
                            float: left;
                        }
                    }
                }
            }
        }
        .back-link{
            a{
                border: 1px solid white;
                border-radius: 5px;
                color: $primary-color;
                transition: all .3s;
                &:hover{
                    border: 1px solid $primary-color;
                    text-decoration: none;
                }
                i{
                    padding-right: 5px;
                }
            }
        }
    }
    .right-col{
        @include bp(min-width, $xs-bootstrap+1){
            padding-left: 0px;
        }
    }
    .scaffold-form{
        padding: 30px 30px 25px;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        background: white;
        border-radius: 5px 5px 0 0;
        @include bp(min-width, $xs-bootstrap+1){
            border-radius: 0 5px 5px 0; 
        }
        
        input{
            height: 33px;
            width: 100%;
            border: 1px solid $c-input-border;
            border-radius: 5px;
            transition: all .3s;
            &:hover{
                border: 1px solid $primary-color;
            }
            &:focus{
                outline: none;
                border: 1px solid $primary-color
            }
        }
        .button{
            border-radius: 5px;
        }
        .fieldset{
            p.required{
                color: $primary-color;
                font-weight: 300;
            }
        }
    }
    .buttons-set{
        max-width: 100%;
        padding-top: 25px;
    }
    footer{
        background: #f9f9f9;
    }
}



.customer-account-login{
    background: #f9f9f9;
    .account-login{
        margin-bottom: 30px;
        margin-top: 20px;
        h2{
            text-transform: lowercase;
            &:first-letter {
                text-transform: uppercase;
                font-size: 20px;
            }
        }
        hr{
            border-top: 4px solid $primary-color;
            width: 70px;
            margin-bottom: 20px;
        }
        .page-title{
            border-bottom: 4px solid $primary-color;
            h1 {
                color: #454545;
                margin-bottom: 5px;
                font-size: 22px;
                font-weight: bold;
                text-transform: uppercase !important;
                padding-bottom: 0;
            }
        }
        .right-col{
            background: white;
            padding: 30px 30px 25px;
            border-radius: 5px 5px 0 0;
            @include bp(min-width, $xs-bootstrap+1){
                border-radius: 0 5px 5px 0; 
            }
            .form-instructions{
                font-family: inherit;
                font-style: normal;
                font-weight: bold;
            }
            p.required{
                color: $primary-color;
                font-weight: 300;
            }
        }
        .left-col{
            padding: 30px 30px 25px;
            color: white;
            background-image: url(../images/bg-login.jpg);
            background-size: cover;
            background-position: 40%;
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
            background-color: $primary-color;
            background-repeat: no-repeat;
            border-radius: 0 0 5px 5px;
            @include bp(min-width, $xs-bootstrap+1){
                border-radius: 5px 0 0 5px; 
            }
            h2{
                color: white;
            }
            hr{
                border-color: white;
            }
            .form-instructions{
                color: white;
                font-family: inherit;
                font-style: normal;
                font-weight: bold;
            }
            .buttons-set{
                padding-top: 20px;
                .button{
                    color: $primary-color;
                    background: white;
                    border-radius: 5px;
                    border: 1px solid white;
                    transition: all .3s;
                    &:hover{
                        background: transparent;
                        color: white;
                    }
                }
            }
            .benefits{
                padding-left: 15px;
                margin-bottom: 20px;
                li{
                    list-style-type: disc;
                    font-size: 13px;
                    font-weight: 300;
                }
            }
        }
        .registered-users{
            input{
                height: 33px;
                border-radius: 5px;
                width: 100%;
                transition: all .3s;
                &:focus{
                    outline: none;
                    border: 1px solid $primary-color
                }
            }
            .buttons-set{
                padding-top: 20px;
                button{
                    border-radius: 5px;
                }
            }
        }
        .scaffold-form{
            margin-top: 25px;
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
            border-radius: 0 25px;
        }
    }
    footer{
        background: #f9f9f9;
    }
    .success-msg,.error-msg{
        li{
            border-left: none;
            border-radius: 5px;
            background: white;
            border: 1px solid;
        }
    }
    .success-msg li{
        border-color: $c-green;
        color: $c-green;
    }
    .error-msg li{
        border-color: $c-red;
        color: $c-red;
    }
}

