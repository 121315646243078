
.one-step-checkout{
    #review_step_header {
        background: white;
        border-bottom: 1px solid #c8c8c8;
        &:before{
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f00c";
            font-size: 16px;
        }
    }
    h3 {
        padding: 0px 0px;
        border-radius: 8px 8px 0 0;
        @media only screen and (max-width: 768px) {
            text-indent: 0px;
        }
    
    }
    #shipping_method_step_header {
        border-top: none;
    }
    .onestepcheckout-login-link{
        a{
            border: 1px solid $primary-color;
            padding: 5px 10px;
            border-radius: 5px;
            transition: all .25s;
            font-weight: 300;
            i{
                margin-right: 5px;
                @include bp(max-width, $bp-xxsmall){
                    display: none;
                }
            }
            &:hover{
                background: $primary-color;
                color: white;
                text-decoration: none;
            }
        }
    }
}

.one-step-checkout{
    .checkout-agreements{
        .agreement-content{
            border-radius: 5px;
            border-color: lightgray;
        }
    }
}

.one-step-checkout .checkout-agreements .agreement-content {
    padding: 15px;
}

.one-step-checkout h3:before {
    width: 41px;
    height: 38px;
    background: $primary-color;
    border-radius: 4px 0 0 0;
    margin-left: 0px !important;
    color: white;
    padding-top: 2px;
    margin-top: -1px;
}

#one-step-checkout-form{
    .checkout-review-load{
        .data-table{
            td{
                .control-qty{
                    a{
                        background: #F3F3F3;
                        color: gray;
                        width: 20px;
                        height: 15px;
                        border-radius: 0 5px 0 0;
                        border: 1px solid lightgray;
                        border-left: none;
                        margin-bottom: 0 !important;
                        &:hover{
                            text-decoration: none;
                        }
                        &:nth-child(2){
                            border-radius: 0 0 5px 0;
                            border-top: none;
                        }
                        .simple-icon{
                            display: block;
                            margin-top: -1px;
                            &:before{
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .mdl-selectfield{
        &:after{
            font-family: "simple-line-icons";
            font-weight: 900;
            content: "\e604" !important;
            color: gray;
            font-size: 12px;
            border: none;
            right: 15px;
            top: 8px;
        }
    }
    .address-information{
        .checkbox-group,
        .input-different-shipping{
            border-radius: 5px;
            padding: 10px 5px 7px;
            input[type=checkbox]:checked ~ label .box-check {
                background:white;
                border-radius: 5px;
            }
            .checkbox-group{
                label{
                    .check{
                        border: 3px solid $primary-color;
                        top: 2px;
                    }
                }
            }
        }
    }
}



#one-step-checkout-form .checkout-review-load .data-table tfoot td, 
#one-step-checkout-form .checkout-review-load .data-table tfoot th,
#one-step-checkout-form .checkout-review-load .data-table th, 
#one-step-checkout-form .checkout-review-load .data-table .even {
    background: white!important;
}

.onestepcheckout-review-info ol li.order-review-info #checkout-review-table-wrapper, 
.order-review-section, 
.onestepcheckout-shipping-payment-review .order-information, 
#showhide_shipping, 
#onestepcheckout-billing-section .billing_address,
.order-review-section{
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid lightgray;
    background: white !important;
    &:hover{
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid white;
    }
}

.order-review-section .payment-method .onestepcheckout-payment-methods .sp-methods .form-list{
    width: 100%;
    padding: 0;
    border: none;
}

body .onestepcheckout-login-link a {
    color: $primary-color;
}

body .one-step-checkout .radioparent .inner {
    background-color: $primary-color;
}

body .one-step-checkout .radioparent input:checked+.outer {
    border: 2px solid $primary-color ;
}

body button.onestepcheckout-btn-checkout {
    background: $primary-color;
    color: white;
    border-radius: 5px;
    box-shadow: none;
    &:hover{
        opacity: 1 !important;
        box-shadow: none;
    }
}

body #one-step-checkout-form .checkout-review-load .data-table td .qty {
    border: 1px solid lightgray;
    border-radius: 5px 0 0 5px;
    height: 30px;
}
body .checkbox-group label .check{
    border: 3px solid $primary-color;
    border-top: none;
    border-left: none;
    top: 2px;
}

#billing-address-select, 
.onestepcheckout-survey select, 
.mdl-selectfield select, 
#billing-new-address-form .mdl-selectfield select{
    border-radius: 0px;
}

#one-step-checkout-form .checkout-review-load .data-table tfoot td, #one-step-checkout-form .checkout-review-load .data-table tfoot th {
    border-right: none!important;
    font-family: inherit;
    border: none;
}

.one-step-checkout h3 {
    text-indent: 0 !important;
}

.onestepcheckout-login-link a span{
    display: none;
}


.onestepcheckout-index-index {
    .page-header{
        @media (min-width: 992px){
            top: 0;
        }
    }
    #header {
        .page-header-container {
            max-width: 100%;
            background-color: $primary-color;
            text-align: center;

            a.logo {
                max-width: 1280px;
                padding: 0 15px;
                margin: 0 auto;
                display: block;
                float: none;
            }
        }
    }
    .proceso-pago{
        color: white;
        font-size: 16px;
        font-weight: bold;
        @include bp(min-width, $bp-medium+1){
            font-size: 22px;
        }
    }
    img{
        width: 230px;
        padding: 5px 0;
        @include bp(min-width, $bp-medium+1){
            padding: 15px 0;
        }
    }
}

#onestepcheckout-login-table, 
#onestepcheckout-forgot-table, 
#onestepcheckout-register-table {
    width: 100%;
    padding: 0;
}


.onestepcheckout-index-index{
    .main-container{
        padding-top: 30px;
    }
    #onestepcheckout-login-popup{
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        padding: 0 15px;
        @include bp(min-width, $xs-bootstrap+1){
            padding: 0 25px;
        }
        .button{
            width: 100% !important;
        }
        .link-register-forgot{
            display: flex;
            width: 100%;
            flex-direction: column;
            @include bp(min-width, $xs-bootstrap+1){
                flex-direction: row;
            }
            a{
                margin-right: 10px;
                font-weight: 300;
                color: $gradient-first-color;
                transition: all .25s;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .onestepcheckout-popup-wrapper{
            h1{
                color: $c-gray;
                text-indent: 0;
                text-align: center;
                margin-top: 15px;
            }
        }
        #onestepcheckout-register-table{
            li {
                padding: 0;
            }
        }
        #onestepcheckout-login-table{
            li {
                padding: 0;
            }
        }
        .close{
            position: absolute;
            background: $third-color !important;
            color: $c-gray;
            opacity: 1;
            right: -10px;
            top: 5px;
            border-radius: 50px;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 38px;
            font-size: 15px;
            @include bp(min-width, $xs-bootstrap+1){
                right: -35px;
                top: 20px;
            }
            &:hover,
            &:focus{
                outline: none;
                opacity: 1 !important;
            }
            a{
                width: 40px;
                height: 40px;
                display: block;
                &:focus{
                    outline: none;
                    opacity: 1 !important;
                }
            }
        }
        .button{
            border-radius: 5px;
            margin-top: 30px;
            height: 40px;
        }
        .title-forgot,
        .title-register{
            text-align: center;
        }
    }
}

.payment-method{
    .conteiner-form-list{
        display: inline-block;
        img{
            width: 100%;
            padding: 0;
        }
    }
}


.onestepcheckout-payment-methods{
    .payment-method{
        display: inline-block;
    }
}

.onestepcheckout-index-index{
    .osc-product-image{
        img{
            padding: 0;
            width: 50px;
            height: 50px;
        }
    }
}

#checkout-review-table{
    tr{
        td{
            .osc-product-image{
                margin-right: 5px;
                width: 50px;
                height: 50px;   
            }
            .product-name{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

table#checkout-review-table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    thead {
        /* head takes the height it requires, 
        and it's not scaled when table is resized */
        flex: 0 0 auto;
        width: calc(100% - 0.9em);
    }
    tbody {
        /* body takes all the remaining available space */
        flex: 1 1 auto;
        display: block;
        overflow: hidden;
        overflow-y: scroll;
        max-height: 235px;
        tr {
            width: 100%;
            td{
                vertical-align: middle;
                .box-qty{
                    width:auto;
                    display:inline-block;
                }
            }
        }
    }
    thead,
    tbody tr {
        display: table;
        table-layout: fixed;
    }
    tfoot{
        order: 13;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-top: 2px solid $secondary-color;
        padding: 15px 0;
        .last{
            strong,
            .price{
                font-weight: bolder;
            }
        }
    }
}

.onestepcheckout-discount{
    .discount-form{
        display: flex;
        #add_coupon_code_button{
            border-radius: 0px 5px 5px 0px;
            margin-top: 22px;
            background: $primary-color;
            box-shadow: none;
            width: 100%;
        }
        .actions-button{
            margin-left: 0;
            width: 32%;
        }
    }
}

.onestepcheckout-comment{
    textarea{
        border: 1px solid silver;
        padding: 5px;
        border-radius: 5px;
        min-height: 80px;
        &:focus{
            border: 1px solid $primary-color;
            outline: none;
        }
    }
}

.comment-legal{
    margin-top: 10px;
    color: $primary-color;
    font-size: 12px;
    font-weight: 300;
    align-items: center;
    i{
        margin-right: 10px;
        font-size: 22px;
    }
}

.onestepcheckout-discount,
.onestepcheckout-comment {
    width: 100% !important;
}