/* ================================================= *
 * General Product List Styles (not module-specific)
 * ================================================= */

/* -------------------------------------------- *
 * Product List General
 */

 .category-title h1,
 body.catalogsearch-result-index .page-title h1 {
     border-bottom: none;
 }
 
 .category-image {
     margin-bottom: $b-margin-bottom;
 }
 
 // Prevent button overlapping when buttons have long text, such as non-English translations
 .products-list .action .button,
 .products-grid .actions .button {
     white-space: normal;
 }

.products-grid{
    .actions{
        .button{
            width: 100%;
            padding: 10px 0;
            @include bp(min-width, $bp-medium+1){
                width: auto;
                padding: 7px 20px;
                border: 2px solid #ADADAD;
            }
        }
    }
}

 
 /* -------------------------------------------- *
  * Product Name
  */
 
 h2.product-name,
 h3.product-name,
 h4.product-name,
 h5.product-name,
 p.product-name {
     @include h2;
     font-size: $f-size-xxl;
     font-family: $f-stack-sans;
     font-weight: 400;
     line-height: 1.4;
     a {
         
         color: $c-gray;
         &:hover {
             text-decoration: none;
         }
         &:focus{
             outline: none;
         }
     }
 }
 
 .products-grid .product-name,
 .products-list .product-name {
     text-transform: none;
     margin-bottom: 0;
     font-size: $f-size-xxl;
     line-height: $f-size-xxl + 2px;
     font-family: $f-stack-sans;
     text-align: center;
     padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
     a {
         font-weight: 400;
     }
 }
 
 /* ============================================ *
  * Product Grid
  * ============================================ */
 
 $product-column-spacing: 20px;
 
 .products-grid {
     position: relative;
     .carousel-sku{
         padding-top: 5px;
     }
 }
 .products-grid.owl-carousel {
     @include bp(max-width, $xs-bootstrap + 1px) {
         width: 100%;
         margin-left: 0;
         margin-right: 0;
     }
     .owl-stage-outer {
         box-sizing: border-box;
         @include bp(max-width, $bp-xsmall) {
             width: calc(100% + 30px);
             padding-left: 15px;
             padding-right: 0px;
             margin-left: -15px;
         }
     }
 }
 
 .owl-carousel {
     .owl-nav {
         position: absolute;
         top: 50%;
         left: 0;
         width: 100%;
         z-index: 100;
        .owl-prev{
            i{
                margin-left: -2px;
            }
        }
        .owl-next{
            i{
                margin-right: -2px;
            }
        }
         button.owl-prev,
         button.owl-next {
             position: absolute;
             top: 50%;
             transform: translateY(-90%);
             left: -5px;
             opacity: 0;
             background: $c-button;
             border-radius: 100%;
             width: 45px;
             height: 45px;
             text-align: center;
             text-indent: -2px;
             transition: all .25s;
             @include transition-property(opacity);
             @include transition-duration(300ms);
 
             i {
                 font-size: 16px;
                 line-height: 45px;
                 color: white;
             }
             &:hover {
                 background: $c-button-hover;
                 i {
                     color: white;
                 }
             }
 
             &:hover,
             &:active,
             &:focus {
                 outline: none;
             }
 
             &.disabled {
                 display: none;
             }
         }
 
         button.owl-next {
            right: -10px;
            left: auto;
         }
         button.owl-prev {
            left: -10px;
            right: auto;
         }
     }
     @include bp(min-width, $xs-bootstrap + 1px) {
         &:hover {
             .owl-nav {
                 .owl-prev,
                 .owl-next {
                     opacity: 1;
                 }
             }
         }
     }
 }
 .products-grid:after {
     @include clearfix;
 }
 
 .products-grid .item {
     position: relative;
     margin-bottom: $product-column-spacing;
     text-align: center;
     background: white;
     border: 1px solid #EEEEEE;
     transition: all .3s;
     display: flex;
     flex-direction: column;
     &:hover{
        @include bp(min-width, $bp-medium+1){
            border-color: white;
        }
     }
     a.product-image {
         position: relative;
         display: block;
         border: none;
         transition: all .3s;
         &:after {
             opacity: 1;
             content: "";
             display: block;
             background: rgba(0,0,0,0.7);
             width: 100%;
             height: 100%;
             position: absolute;
             z-index: 1;
             top: 0;
             left: 0;
             opacity: 0;
             @include transition-property(opacity);
             @include transition-duration(300ms);
             will-change: opacity;
         }
     }
     &:after {
         display: block;
         content: "";
         pointer-events: none;
         position: absolute;
         -webkit-border-radius: 4px;
         border-radius: 4px;
         z-index: 1;
         width: 100%;
         height: 100%;
         box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
         opacity: 0;
         top: 0;
         @include transition-property(opacity);
         @include transition-duration(300ms);
         will-change: opacity;
     }
     @include bp(min-width, $xs-bootstrap + 1px){
         &:hover {
             .product-info {
                 .actions {
                     display: block;
                 }
             }
             a.product-image {
                 position: relative;
                 display: block;
                 &:after {
                     content: "";
                     display: block;
                     background: rgba(0,0,0,0.3);
                     width: 101%;
                     height: 100%;
                     position: absolute;
                     z-index: 1;
                     top: 0;
                     left: -1px;
                 }
                 img{
                    filter: grayscale(100%);
                    transition-delay: 0.2s;
                 }
             }
             &:after {
                 opacity: 1;
             }
         }
     }
     @include bp(max-width, $xs-bootstrap + 1px){
         border: 1px solid $c-module-border-light;
     }
 }
 .products-grid .product-image {
     width: 100%;
     margin-bottom: 15px;
 }
 
 .products-grid .product-image img {
     width: 100%;
     height: auto;
 
     &:hidden {
         display: none;
     }
 }
 
 .widget-new-products {
     .products-grid .product-image {
         width: 100%;
         margin-bottom: 15px;
     }
 }
 
 // If the height of the .product-info element changes, this value should be adjusted
 $productsGridActionHeight: 85px;
 
 .products-grid .product-info {
     position: static;
     min-height: auto !important;
     padding-bottom: 10px !important;
     margin-bottom: 30px;
     @include bp(min-width, $xs-bootstrap+1) {
         margin-bottom: 0;
     }
 }
 .products-grid .actions {
     padding-top: $box-spacing;
     width: 100%;
     @include bp(max-width, $xs-bootstrap){
        min-height: unset;
        position: absolute;
        width: 100%;
        bottom: -1px;
    }
     @include bp(min-width, $xs-bootstrap + 1px) {
         position: absolute;
         top: 90px;
         z-index: 2;
         opacity: 0;
         @include transition-property(opacity);
         @include transition-duration(600ms);
     }
 }
 .products-grid .item {
     .product-image {
         &:after {
             content: "";
             opacity: 0;
             background: rgba(0,0,0,0.7);
             position: absolute;
             z-index: 1;
             top: 0;
             left: 0;
             width: 100%;
             height: 100%;
             @include transition-property(opacity);
             @include transition-duration(600ms);
         }
     }
 }
 .products-grid .item:hover {
     .actions {
         opacity: 1;
     }
     @include bp(min-width, $xs-bootstrap + 1px) {
         .product-image:after {
             opacity: 1;
             @include transition-property(opacity);
             @include transition-duration(600ms);
         }
     }
 }
 
 .products-grid .ratings .rating-box {
     margin: 0 auto;
 }
 
 .products-grid .ratings .amount {
     display: none;
 }
 
 .products-grid .price-box {
     color: $c-text-gray;
     font-size: $f-size-s;
     margin: 0 0 10px;
 }
 .products-list .item {
     position: relative;
 }
 .products-list .price-box .discount-label,
 .products-grid .price-box .discount-label {
     position: absolute;
     z-index: 0;
     top: 1px;
     left: 1px;
     font-size: 2rem;
     color: white;
     background: $primary-color;
     padding: 5px;
     display: block;
     line-height: 2rem;
     font-weight: 700;
     small {
         font-size: 1.5rem;
         line-height: 1.5rem;
         display: block;
         font-weight: 400;
     }
 }
 .products-grid .add-to-links {
     margin-bottom: 0;
 }
 
 // ---------------------------------------------
 
 /* Config: Two columns + flexible gutter */
 
 // Container: 480 - (2 * 20 trim) = 440
 // Math: (440 - (20 gutter)) / 2 items = 210
 
 .products-grid > li {
     float: left;
     width: percentage(210 / 440);
     margin-right: percentage(20 / 440);
 }
 
 .products-grid > li:nth-child(odd) {
     clear: left;
 }
 
 .products-grid > li:nth-child(even) {
     margin-right: 0;
 }
 
 .products-grid .product-image {
     margin-bottom: 5px;
 
     @include loadingOverlay();
 }
 
 // ---------------------------------------------
 
 @include bp(min-width, $bp-xsmall + 1) {
     /* Config: Three columns + flexible gutter */
     // Container: 600 - (2 * 30 trim wide) = 540
     $column-count: 3;
     $column-gutters: 2;
     $container: 600 - (2 * $trim);
 
     // Math: (540 - (2 * 30 gutter)) / 3 items = 160
     $column-width: ($container - ($column-gutters * $product-column-spacing)) / $column-count;
 
     .products-grid {
         /* Undo two-column config */
         > li:nth-child(odd) {
             clear: none;
         }
         > li:nth-child(even) {
             margin-right: percentage($product-column-spacing / $container);
         }
 
         /* Set three-column config */
         > li {
             width: percentage($column-width / $container);
             margin-right: percentage($product-column-spacing / $container);
         }
         > li:nth-child(#{$column-count}n+1) {
             clear: left;
         }
         > li:nth-child(#{$column-count}n) {
             margin-right: 0;
         }
     }
 }
 
 // This mixin outputs the styles to allow for grids with more than 3 columns
 @mixin product-grid($column-count, $container-width, $class-append:"") {
 
     // Allow this mixin to be used for more specific purposes, such as grids contained within widgets
     @if $class-append != "" {
         $class-append: -#{$class-append};
     }
 
     /* Config: Columns + flexible gutter */
     $column-gutters: ($column-count) - 1;
     $container: $container-width - (2 * $trim);
     $column-width: ($container - ($column-gutters * $product-column-spacing)) / $column-count;
 
     /* Undo three-column config */
     .products-grid--max-#{$column-count}-col#{$class-append} > li:nth-child(odd) {
         clear: none;
     }
     .products-grid--max-#{$column-count}-col#{$class-append} > li:nth-child(3n+1) {
         clear: none;
     }
     .products-grid--max-#{$column-count}-col#{$class-append} > li:nth-child(even),
     .products-grid--max-#{$column-count}-col#{$class-append} > li:nth-child(3n) {
         margin-right: percentage($product-column-spacing / $container);
     }
 
     /* Set column config */
     .products-grid--max-#{$column-count}-col#{$class-append} > li {
         margin-right: percentage($product-column-spacing / $container);
     }
     .products-grid--max-#{$column-count}-col#{$class-append} > li {
         width: percentage($column-width / $container);
         margin-right: percentage($product-column-spacing / $container);
     }
     .products-grid--max-#{$column-count}-col#{$class-append} > li:nth-child(#{$column-count}n+1) {
         clear: left;
     }
     .products-grid--max-#{$column-count}-col#{$class-append} > li:nth-child(#{$column-count}n) {
         margin-right: 0;
     }
 }
 
 // ---------------------------------------------
 
 @include bp(min-width, 960px) {
     @include product-grid(4, 960);
     @include product-grid(5, 960);
     @include product-grid(6, 960);
 }
 
 /* ============================================ *
  * Product List
  * ============================================ */
 
 .products-list {
     margin-top: 20px;
     margin-bottom: 20px;
 
     & > li {
         padding-bottom: 20px;
         margin-bottom: 20px;
         border-bottom: 1px solid $c-module-border-light;
 
         &:after {
             @include clearfix;
         }
         .product-image {
             float: left;
             width: percentage(1/3);
             background: white;
             padding: 10px;
             @include bp(min-width, $bp-large+1){
                width: 23%;
             }
             img {
                width: auto;
                max-width: 100%;
                height: 100px;
                margin-left: auto;
                margin-right: auto;
                @include bp(min-width, $xs-bootstrap+1){
                    height: 150px;
                }
             }
         }
     }
     & > li:last-child {
         border-bottom: none;
     }
 }
 
 .products-list .product-info,
 .products-list .product-shop {
     float: right;
     width: percentage(2/3);
     padding-left: ($box-spacing * 2);
     @include bp(min-width, $bp-large+1){
         float: left;
         width: 75%;
     }
     .product-name {
         margin-bottom: 0;
         text-align: left;
         padding-left: 0;
         padding-right: 0;
     }
     .ratings {
         margin: 0;
 
         .rating-box,
         .rating-links {
             float: left;
             margin-right: $element-spacing;
         }
         .rating-links {
             margin-top: 4px;
         }
     }
     .price-box {
         margin-top: 10px;
 
     }
     .price-box .price {
         font-size: $f-size-xxl;
     }
     .action {
         margin: $element-spacing 0;
     }
     .product-secondary {
         float: left;
         width: 100%;
         text-align: left;
         clear: right;
     }
 }
 
 @include bp(min-width, $bp-xsmall + 1) {
     .products-list .product-name a {
         font-size: $f-size-xxl;
     }
 }
 
 @include bp(max-width, 600px) {
     .products-list .product-shop .price-box .special-price {
         padding-left: 0;
     }
     .products-list .product-shop {
         .product-primary,
         .product-secondary,
         .desc {
             float: none;
             width: 100%;
             padding-right: 0;
             text-align: left;
         }
     }
 }
 
 /* ============================================ *
  * Mini Product List
  * ============================================ */
 
 .mini-products-list {
 
     .product-image {
         float: left;
         min-width: 50px;
     }
     .product-details {
         margin-left: 60px;
     }
 
     // Vertically center product name
     .product-details .product-name {
         padding-top: 10px;
     }
 
     li {
         margin-top: 5px;
         margin-bottom: 0px;
         clear: both;
 
         &:after {
             @include clearfix();
         }
     }
 }
 
 .mini-products-images-list {
 
     &:after {
         @include clearfix;
     }
 
     li.item {
         float: left;
         width: 50%;
         margin: 0;
         padding: 0 5px 10px 0;
 
         &:nth-child(even) {
             padding-right: 0;
             padding-left: 5px;
         }
 
         .product-image {
             display: block;
 
             img {
                 width: 100%;
                 max-width: 100%;
             }
         }
     }
 
 }
 
 
 
 /* ============================================ *
  * Catalog - List
  * ============================================ */
 .catalog-category-view {
     .breadcrumbs{
         display: none;
     }
     .col2-left-layout .category-title {
         @include bp(min-width, $xs-bootstrap + 1px) {
             position: relative;
             left: -272px;
         }
     }
     .col2-left-layout .col-left {
         @include bp(min-width, $xs-bootstrap + 1px) {
             padding-top: 70px;
         }
     }
 }
 .category-image {
     img {
         width: 100%;
         max-width: 100%;
 
         &:hidden {
             display: none;
         }
     }
 }
 
 /* ============================================ *
  * Layered Navigation
  * ============================================ */
 
 .block-layered-nav .block-subtitle--filter {
     // Will be shown at smaller viewports
     display: none;
     @include accordionDtBase;
     cursor: pointer;
 }
 
 .block-layered-nav .block-content {
     // Accordions are fully expanded until a smaller resolution.
     @include accordion;
 }
 
 .block-layered-nav dl dd {
     margin-bottom: $box-spacing;
     margin-top: $box-spacing;
 
     ol > li > span, ol > li > a {
         padding: 0;
         display: block;
         font-size: 15px;
         line-height: 2.2rem;
         color: $c-gray;
         font-weight: 300;
         letter-spacing: 0.5px;
         @include transition-property(color);
         @include transition-duration(300ms);
         @include bp(max-width, $xs-bootstrap) {
             font-size: 16px;
             padding: 5px 0;
             letter-spacing: 1px;
         }
         &:first-letter{
             text-transform: uppercase;
         }
         &:hover {
             color: $c-action;
             text-decoration: none;
         }
     }
     ol > li > a .count {
         color: $c-text-gray;
     }
 }
 
 @include bp(min-width, $bp-medium+1) {
     .block-layered-nav .block-content > dl > dt {
         padding-left: 0;
 
         &:after {
             display: none;
         }
     }
 }
 
 @include bp(max-width, $bp-medium) {
     .sidebar .block.block-layered-nav {
         border-bottom: none;
     }
 
     // We don't want "Shop By" to show when there are no filters applied, as the "Filter" link will suffice
     .block-layered-nav--no-filters .block-title {
         display: none;
     }
 
     // Remove the top padding and re-add it as margin-top so that the margin will collapse with elements above it
     .block-layered-nav .block-content {
         padding-top: 0;
         margin-top: 15px;
         @include accordionCollapse;
     }
 
     .block-layered-nav .block-subtitle--filter {
         background-color: $c-action;
         border: 0;
         margin-bottom: 0;
         display: block;
         color: #FFFFFF;
         padding: 10px 25px;
 
         &:after {
             @include triangle(right, 4px, #FFFFFF);
             right: 20px;
             top: 50%;
             margin-top: -5px;
         }
 
         &:hover {
             background-color: $c-button-hover;
             color: #FFFFFF;
         }
 
         &.active:after {
             @include triangle(down, 4px, #FFFFFF);
             right: 20px;
             top: 50%;
             margin-top: -3px;
         }
     }
 
     #narrow-by-list,
     #narrow-by-list2 {
         padding: $box-spacing;
         border: 1px solid $c-module-border;
         border-top: 0;
     }
 
     .block-layered-nav dl ol > li > a,
     .block-layered-nav dl ol > li > span {
         padding: 7px;
         margin: 5px;
     }
 
     .block-layered-nav dl ol > li > a {
         @include touch-button;
         background: none;
 
         &:hover {
             text-decoration: none;
             background: none;
         }
     }
 }
 
 .block-layered-nav .currently {
     .block-subtitle {
         display: block;
         @include h4;
     }
 
     ol {
         padding-left: $box-spacing;
         margin-bottom: $box-spacing;
     }
     ol li {
         margin-bottom: 5px;
 
         .label {
             font-weight: bold;
         }
 
         &:after {
             @include clearfix;
         }
     }
 }
 
 .block-layered-nav .actions {
     text-align: right;
     margin-bottom: $element-spacing;
 }
 
 .catalog-category-view .products-grid{
     display: flex;
     flex-wrap: wrap;
 }
 
 .catalog-category-view{
     .products-grid{
         .actions{
             min-height: auto;
         }
         .add-to-links{
             display: none;
         }
     }
 }
 
 
 .products-list{
     .out-of-stock{
         background: lightgray;
         display: table;
         padding: 0 32px;
         cursor: not-allowed;
         height: auto;
         padding: 7px 34px;
         text-transform: uppercase;
         &:before{
             display: none;
         }
     }
     .f-fix{
         display: flex;
         flex-direction: column;
         .product-primary{
             order: 0;
         }
         .desc{
             font-weight: 300;
             order: 1;
             margin-top: 0;
         }
         .product-secondary{
             order: 2;
         }
     }
     .no-rating{
         display: none;
     }
     .add-to-links{
         display: none;
     }
     .product-name{
         min-height: auto;
     }
 }
 
.category-products{
    .sort-by{
        label{
            display: none;
            @include bp(min-width, $xs-bootstrap+1){
                display: block;
            }
        }
    }
    .view-mode{
        margin-left: 0px;
        @include bp(min-width, $xs-bootstrap+1){
            margin-left: 30px;
        }
        .list,.grid{
            padding-top: 3px;
            &:after{
                font-size: 20px;
                @include bp(min-width, $xs-bootstrap+1){
                    font-size: 25px;
                }
            }
        }
    }
    .owl-carousel{
        .add-to-links{
            display: none;
        }
    }
}
 
.catalogsearch-result-index{
    .add-to-links{
        display: none;
    }
}

.meanbee-infinitescroll-busy{
    img{
        margin-left: auto;
        margin-right: auto;
    }
}
