/* ============================================ *
 * Footer
 * ============================================ */

/* -------------------------------------------- *
 * Footer
 */

 #footer {
    clear: both;
    width: 100%;
    .block-title {
        border-bottom: 0;
        padding: 3px 0;
        margin-bottom: 10px;
    }

    .block-title,
    address {
        color: $c-text-primary;
    }

    .links {
        float: left;
        width: 20%;
        padding: 0 $trim/2 0 $trim/2;
        margin-bottom: $trim;

        li {
            word-break: break-all;
            line-height: 2rem;
            font-size: 1rem;
            font-weight: 100;
            color: #ccc;
        }
        a {
            color: #ccc;
            @include transition-property(color);
            @include transition-duration(300ms);
            &:hover {
                color: white;
            }
        }
        .bar-contact{
            a{
                color: white;
                background: $primary-color;
                padding: 1px;
                border-radius: 4px;
                font-size: 13px;
                margin-bottom: 10px;
                display: table;
                font-weight: 300;
                letter-spacing: 0.5px;
                &:hover{
                    text-decoration: none;
                }
                span{
                    background: $c-footer;
                    border-radius: 4px;
                    padding: 0px 10px;
                    display: block;
                    .icon-screen-tablet{
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    .block-subscribe {
        float: none;
        width: 100%;
        margin-bottom: $trim;
    }

    .form-subscribe-header {
        display: none;
    }
    .suscriptionTitle {
        text-align: right;
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1.75rem;
        color: white;
        margin: 0;
        @include bp(max-width, $xs-bootstrap) {
            text-align: center;
            margin-bottom: 10px;
        }
    }
    .newsletter-subscribe {
        .form-group {
            margin: 0;
        }
        .input-text {
            height: 50px;
            padding-left: 15px;
            padding-right: 15px;
            @include inputTransparentBordered(22px);
            display: inline-block;
            vertical-align: top;
            border-color: white;
            &::placeholder {
                color: white;
            }
            &:-ms-input-placeholder {
                color: white;
            }
            @include bp(max-width, $bp-large) {
                width: 280px;
            }
            @include bp(min-width, $xs-bootstrap+1) {
                border: 1px solid white;
                color: white;
            }
            @include bp(max-width, $xs-bootstrap) {
                width: 100%;
            }
        }
        .button {
            height: 50px;
            background: none;
            border: 1px solid white;
            border-left: none;
            display: inline-block;
            vertical-align: top;
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }
            @include bp(max-width, $xs-bootstrap) {
                border: 1px solid white;
                border-top: none;
                width: 100%;
                display: block;
            }
        }
        p.required, .validation-advice {
            color: white;
            position: absolute;
            bottom: -20px;
        }
    }
    .copyright {
        padding-top: 3px;
        font-size: 13px;
        align-items: center;
        flex-direction: column;
        text-align: center;
        border-bottom: 1px solid lightgray;
        padding-bottom: 10px;
        margin-bottom: 5px;
        img {
            width: auto;
            height: 30px;
            margin-right: 5px;
            @include bp(min-width, $bp-xsmall+1){
                height: 18px;
            }
        }
        @media (min-width: 576px) {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        @include bp(min-width, $xs-bootstrap+1) {
            text-align: left;
            font-size: 12px;
            flex-direction: row;
        }
    }
    .links.metodosPago {
        img {
            margin-bottom: 1.5rem;
        }
    }
    .links.horariosRedes {
        .socialMedia {
            margin-top: 25px;
            li {
                display: inline-block;
                letter-spacing: 15px;
                a {
                    font-size: 32px;
                    line-height: 40px;
                    color: white;
                    @include transition-property(color);
                    @include transition-duration(300ms);
                    &:hover {
                        color: #ccc;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    /* -------------------------------------------- *
     * Social icons
     */

    .links.social-media em {
        background-image: url(../images/social_icons.png);
        background-repeat: no-repeat;
        width: 13px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 7px;
    }

    @include if-min-resolution(2) {
        .links.social-media em {
            background-image: url(../images/social_icons@2x.png);
            background-size: 50px 125px;
        }
    }

    @mixin footer-social-icon($class, $y, $height: 13) {
        .links.social-media em.#{$class} {
            background-position: 0 -#{$y}px;
            height: #{$height}px;
        }

        .links.social-media a:hover em.#{$class} {
            background-position: -25px -#{$y}px;
        }
    }

    @include footer-social-icon(facebook, 0);
    @include footer-social-icon(twitter, 24);
    @include footer-social-icon(youtube, 49);
    @include footer-social-icon(pinterest, 74, 15);
    @include footer-social-icon(rss, 99);

    // ---------------------------------------------

    .block-subscribe .input-box {
        float: left;
        width: percentage(210px/320px);
        padding-top: 0;
    }

    .block-subscribe .input-text {
        width: 100%;
        border-right: 0;
        @include border-radius(0);
    }

    .block-subscribe .block-content {
        padding-top: 7px;

        &:after {
            @include clearfix;
        }
    }

    .block-subscribe .actions {
        float: left;
        width: percentage(110px/320px);
        margin: 0;
    }

    .block-subscribe .actions .button {
        height: 30px;
        line-height: 17px;
        float: left;
    }

    .bugs,
    address {
        clear: both;
    }

    .bugs {
        display: none;
    }

    .form-language,
    .currency-switcher,
    .store-switcher {
        clear: both;
        margin-bottom: $gap;
    }

    .form-language,
    .currency-switcher {
        display: none;
    }

    address {
        border-top: 1px solid $c-module-border;
        text-align: center;
        width: 100%;
        font-size: $f-size-xxs;
        margin-top: $trim;
        padding: $trim 0;
    }

    @include bp(max-width, $bp-xlarge) {
        $links-trim: percentage(30px / 960px);
        $links-width: (100 - ($links-trim * 3)) / 4;

        .links {
            width: $links-width;
            padding-right: 0;
            margin-right: $links-trim;
        }

        .links:nth-child(4) {
            margin-right: 0;
        }

        .block-subscribe {
            clear: both;
            float: none;
            margin: 0 auto $trim auto;
        }
    }

    @include bp(max-width, $bp-medium) {
        .form-language,
        .currency-switcher {
            display: block;
        }
    }

    @include bp(max-width, $xs-bootstrap) {
        $links-trim: percentage(15px / 480px);
        $links-width: (100 - $links-trim) / 2;

        .links {
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;
            &.active {
                .block-title {
                    &:after {
                        content: "\f077" !important;
                    }
                }
            }
            .block-title {
                margin-bottom: 0;
                padding: 15px 15px !important;
                border-bottom: 1px solid $c-gray;
                text-align: left;
                &:after {
                    display: block !important;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f078" !important;
                    font-size: 1.2rem;
                    position: absolute;
                    top: 15px;
                    right: 20px;
                    left: auto;
                    border: none;
                    color: white;
                }
            }
            ul {
                display: none;
                padding: 10px 15px;
            }
        }

        .links {
            padding-left: 0;
            padding-right: 0;
        }
        .links:nth-child(odd) {
            margin-right: 0;
            clear: both;
        }

        .block-subscribe {
            clear: both;
            float: left;
            width: 100%;
        }
    }
}
.onestepcheckout-index-index {
    footer#footer {
        display: none;
    }
    .footer-container {
        display: none;
    }
}
